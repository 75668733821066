<template>
  <div class="callBindLogContainerFlexColumn">
    <div class="callBindLogContainerFlexColumn__top">
      <div class="title">登录日志</div>
      <el-divider />
    </div>
    <dc-search-panel :params="queryParams" style="border: none; background-color: white; margin-bottom: 0" @search="doSearch" @reset="reset">
      <dc-form-item label="时间(起)">
        <el-date-picker v-model="queryParams.startDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
      </dc-form-item>
      <dc-form-item label="时间(止)">
        <el-date-picker v-model="queryParams.endDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
      </dc-form-item>
      <dc-input field="username" label="客服名称"></dc-input>
      <dc-form-item label="类型">
        <el-select v-model="queryParams.type">
          <el-option key="" label="全部" value="">全部</el-option>
          <el-option :key="1" label="绑定" :value="1">绑定</el-option>
          <el-option :key="0" label="解绑" :value="0">解绑</el-option>
        </el-select>
      </dc-form-item>
    </dc-search-panel>
    <div class="buttons">
      <el-button type="primary" @click="getNowDay">当天</el-button>
      <el-button type="primary" @click="getMonth">当月</el-button>
      <el-button type="primary" @click="doSearch">查询</el-button>
      <el-button type="primary" @click="reset">重置</el-button>
    </div>
    <div class="callBindLogContainerFlexColumn__body">
      <dc-datatable ref="list" :url="apiUrl" :params="queryParams">
        <el-table-column prop="suser" label="客服名称" align="center"></el-table-column>
        <el-table-column prop="cphone" label="客服手机号" align="center"></el-table-column>
        <el-table-column prop="ptype" label="类型" align="center">
          <template #default="scope">
            {{ scope.row.ptype | getTypeText }}
          </template>
        </el-table-column>
        <el-table-column prop="tcreate" label="时间" align="center">
          <template #default="scope">
            {{ scope.row.tcreate | dateFilter }}
          </template>
        </el-table-column>
      </dc-datatable>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "CallBindLog",
  filters: {
    dateFilter(timestamp) {
      return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
    },
    getTypeText(type) {
      if (type == 1) {
        return "绑定";
      } else if (type == 0) {
        return "解绑";
      } else return "";
    },
  },
  data() {
    return {
      apiUrl: `${this.baseUrls.callBind}/queryPage`,
      queryParams: {},
    };
  },
  created() {
    this.resetPara();
  },
  methods: {
    doSearch() {
      this.$refs.list.load();
    },
    getMonth() {
      // 获取当前月份的第一天
      this.queryParams.startDate = moment().startOf("month").format("YYYY-MM-DD");

      // 获取当前月份的最后一天
      this.queryParams.endDate = moment().endOf("month").format("YYYY-MM-DD");
      this.doSearch();
    },
    getNowDay() {
      // 获取当前月份的第一天
      this.queryParams.startDate = moment().format("YYYY-MM-DD");

      // 获取当前月份的最后一天
      this.queryParams.endDate = moment().format("YYYY-MM-DD");
      this.doSearch();
    },
    reset() {
      this.resetPara();
      this.$nextTick(() => this.doSearch());
    },
    resetPara() {
      this.queryParams = {
        username: "",
        type: "",
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().endOf("month").format("YYYY-MM-DD"),
      };
    },
  },
};
</script>

<style lang="scss">
.callBindLogContainerFlexColumn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;

  overflow: hidden;
  .el-date-editor {
    width: 170px;
  }

  &__top {
    background-color: white;

    .title {
      font-size: 18px;
    }

    padding: 10px;

    .el-divider {
      margin: 12px 0;
    }

    .tips {
      margin-top: 10px;
      padding: 10px;
      background-color: #f6f6f6;
    }

    .label {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .buttons {
    text-align: right;
    clear: both;
    padding-right: 10px;
    background-color: white;
  }
  &__body {
    flex: 1;
    border-top: none;
    padding: 10px;
    background-color: white;
  }
}
</style>
